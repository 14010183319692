/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {evaluateFeatureFlags} from '@/feature-flags/evaluate-feature-flags';
import {
  getCustomerPrivacy,
  getTrackingConsent,
} from '@/privacy/getTrackingConsent';
import {initializeRecharge} from '@/recharge-storefront/client';
import {logError, logInfo} from '@/utilities/log';
import {datadogRum} from '@datadog/browser-rum';
import {StatsigClient} from '@statsig/js-client';
import datadogConfig from '~/datadog.config';

const shopDomain = import.meta.env.VITE_PUBLIC_STORE_DOMAIN as string;
const statsigClientToken = import.meta.env.VITE_STATSIG_CLIENT_TOKEN as string;
const environmentTier = import.meta.env.VITE_ENVIRONMENT_TIER as string;
const rechargeStorefrontToken = import.meta.env
  .VITE_RECHARGE_STOREFRONT_API_TOKEN as string;

/**
 * This entrypoint is for code that MUST execute in the head of every page on the site.
 * This should be limited to things that are required to run before the rest of the page is loaded.
 *
 * If you need to run code on every page, but it doesn't need to run in the head, use the
 * `src/entrypoints/main.tsx` entrypoint instead.
 */

handleInitDatadog();
handleInitRecharge().catch(logError);
handleInitStatsig();

// Load feature flags from cart attributes and URL parameters
evaluateFeatureFlags().catch(logError);

/**
 * Initializes Datadog RUM and logs any errors that occur.
 * This function also sets the tracking consent status for Datadog,
 * and listens for changes to the tracking consent status.
 */
function handleInitDatadog() {
  try {
    const customerPrivacy = getCustomerPrivacy();

    // Uncomment this line to enable tracking consent:
    // const needsConsent = isConsentRequired(customerPrivacy);
    const needsConsent = false;

    if (needsConsent) {
      datadogRum.init({
        ...datadogConfig,
        trackingConsent: getTrackingConsent(customerPrivacy),
      });

      document.addEventListener('visitorConsentCollected', () => {
        datadogRum.setTrackingConsent(getTrackingConsent(customerPrivacy));
      });
    } else {
      datadogRum.init(datadogConfig);
    }
  } catch (error) {
    logError(error, {message: 'ErrorInitializingDatadog'});
  }
}

function handleInitStatsig() {
  try {
    // Initialize Statsig client
    // https://docs.statsig.com/client/javascript-sdk
    const statsigClient = new StatsigClient(
      statsigClientToken,
      {userID: 'statsig-user'},
      {environment: {tier: `${environmentTier}`}}
    );
    statsigClient.initializeSync();

    // make statsig client available globally
    (
      window as unknown as Window & {statsigClient: StatsigClient}
    ).statsigClient = statsigClient;
    return statsigClient;
  } catch (error) {
    logError(error, {message: 'ErrorInitializingStatsig'});
  }
}

/**
 * Initializes Recharge storefront client and logs any errors that occur.
 */
async function handleInitRecharge() {
  const options = {
    storeIdentifier: shopDomain,
    storefrontAccessToken: rechargeStorefrontToken,
  };
  try {
    if (!shopDomain) {
      throw new Error('Missing VITE_PUBLIC_STORE_DOMAIN variable');
    }

    if (!rechargeStorefrontToken) {
      throw new Error('Missing VITE_RECHARGE_STOREFRONT_API_TOKEN variable');
    }

    await initializeRecharge(options);

    logInfo('InitializedRecharge', options);
  } catch (error) {
    logError(error, {message: 'ErrorInitializingRecharge', ...options});
  }
}
